import React, { useEffect, useState } from 'react';
import './home-v3-hero.css';
import HostVideo from '../host-video';
import AppButton from '../CustomComponents/app-button';
import bannerImage from '../../assets/home-v3/hero image.png';
import person1 from '../../assets/home-v3/person1.png';
import person2 from '../../assets/home-v3/person2.png';
import person3 from '../../assets/home-v3/person3.png';
import company1 from '../../assets/home-v3/company1.png';
import company2 from '../../assets/home-v3/company2.png';
import company3 from '../../assets/home-v3/company3.png';
import useMediaQuery from '../../hooks/useMediaQuery';
import pdf1 from '../../assets/home-v3/Hexagon.pdf';
import pdf2 from '../../assets/home-v3/Cority.pdf';
import { Link } from 'gatsby';
import hexagon from '../../pages/case-studies/hexagon';

function HomePageHero() {
    const isMobile = useMediaQuery('(max-width: 1024px)');
    const [activeCardIndex, setActiveCardIndex] = useState(0);

    function togglePopUp(n: number, show: boolean) {
        const popUpCard = document.querySelectorAll('.popup-card');
        const arrowBox = document.querySelectorAll('.arrow-box svg');
        const herobtn = document.querySelector('.home-hero-btn');
        const card = popUpCard[n] as HTMLElement;
        const arrow = arrowBox[n] as HTMLElement;

        if (card) {
            if (!isMobile) {
                card.style.transform = show ? 'translateY(0)' : 'translateY(91%)';
                arrow.style.transform = show ? 'rotate(180deg)' : 'rotate(0deg)';
                (herobtn as HTMLElement).style.zIndex = show ? '1' : '2';
            } else {
                card.style.transform = show ? 'translateY(0)' : 'translateY(150%)';
            }
        }
    }

    useEffect(() => {
        if (isMobile) {
            const progress = document.querySelectorAll('.progress');
            const popUpCard = document.querySelectorAll('.popup-card');

            const intervalId = setInterval(() => {
                popUpCard.forEach((cards, i) => {
                    const card = cards as HTMLElement;
                    card.style.transform = activeCardIndex === i ? 'translateY(0)' : 'translateY(150%)';
                });

                setActiveCardIndex((prevIndex: number) => (prevIndex + 1) % 3);

                progress.forEach((el, index) => {
                    const progressBar = el as HTMLElement;

                    if (index !== activeCardIndex) {
                        progressBar.style.opacity = '0.5';
                    } else {
                        progressBar.style.opacity = '1';
                    }
                });
            }, 5000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isMobile, activeCardIndex]);

    const Arrow = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path
                d="M7 11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11L7 11ZM8.70711 0.292893C8.31658 -0.0976315 7.68342 -0.0976314 7.29289 0.292893L0.928932 6.65685C0.538407 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 11L9 1L7 1L7 11L9 11Z"
                fill="white"
                fill-opacity="0.75"
            />
        </svg>
    );

    const RightArrow = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
            <path
                d="M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.34315 0.928932C6.95262 0.538407 6.31946 0.538407 5.92893 0.928932C5.53841 1.31946 5.53841 1.95262 5.92893 2.34315L11.5858 8L5.92893 13.6569C5.53841 14.0474 5.53841 14.6805 5.92893 15.0711C6.31946 15.4616 6.95262 15.4616 7.34315 15.0711L13.7071 8.70711ZM1 9L13 9L13 7L1 7L1 9Z"
                fill="#08758D"
            />
        </svg>
    );

    return (
        <div className="home-page-hero">
            <div className="home-content">
                <div className="text-content-wrapper">
                    <h1 className="animate">
                        <span>Case Closed:</span> Supercharge your Technical Support
                    </h1>
                    <p className="animate">AI for Case Resolution: streamline your customers’ support journey from start to finish</p>
                    <AppButton variant="animate button-style home-hero-btn" text="Supercharge Your Agents" linkTo="/get-started" />
                </div>
                <div className="video-block">
                    {/* <HostVideo videoSrcURL={undefined} videoTitle={undefined} className={'home-video-wrapper animate'} /> */}
                    <img src={bannerImage} />
                </div>
            </div>
            <div className="home-black-strip">
                <div className="popup-card-holder">
                    <div className="popup-card" onMouseLeave={() => togglePopUp(0, false)}>
                        <div className="arrow-box" onMouseOver={() => togglePopUp(0, true)}>
                            <Arrow />
                        </div>
                        <Link to={`/case-studies/hexagon`} className="card-hexgon">
                            <div className="card-top">
                                <div className="card-image">
                                    <img src={person1} loading="lazy" />
                                </div>
                                <div className="card-details">
                                    <div className="author-section">
                                        <p className="author-name">
                                            <span>Adam</span> Savage
                                        </p>
                                        <p className="author-desc">Chief Operating Officer</p>
                                    </div>
                                    <div className="author-quote">
                                        “Our agent efficiency is up by <span>50%</span> with AptEdge, reducing headcount and onboarding
                                        costs”
                                    </div>
                                </div>
                            </div>
                            <div className="card-bottom">
                                <img src={company1} />
                                <a>
                                    {' '}
                                    Download Case Study <RightArrow />
                                </a>
                            </div>
                        </Link>
                    </div>
                    <div className="popup-card" onMouseLeave={() => togglePopUp(1, false)}>
                        <div className="arrow-box" onMouseOver={() => togglePopUp(1, true)}>
                            <Arrow />
                        </div>
                        <Link to={`/case-studies/cority`} className="card-cority">
                            <div className="card-top">
                                <div className="card-image">
                                    <img src={person2} loading="lazy" />
                                </div>
                                <div className="card-details">
                                    <div className="author-section">
                                        <p className="author-name">
                                            <span>Chris</span> Slaugh
                                        </p>
                                        <p className="author-desc">VP Global Customer Support</p>
                                    </div>
                                    <div className="author-quote">
                                        “Now our brand new analysts come on board and they hit that time-to-productivity number within 2
                                        months.”
                                    </div>
                                </div>
                            </div>
                            <div className="card-bottom">
                                <img src={company2} loading="lazy" />
                                <a>
                                    Download Case Study <RightArrow />
                                </a>
                            </div>
                        </Link>
                    </div>
                    <div className="popup-card" onMouseLeave={() => togglePopUp(2, false)}>
                        <div className="arrow-box" onMouseOver={() => togglePopUp(2, true)}>
                            <Arrow />
                        </div>
                        <Link to={`/videos/empower-agents-ai-boosting-productivity`} className="card-Charles">
                            <div className="card-top">
                                <div className="card-image">
                                    <img src={person3} />
                                </div>
                                <div className="card-details">
                                    <div className="author-section">
                                        <p className="author-name">
                                            <span>Charles</span> Coaxum
                                        </p>
                                        <p className="author-desc">Former Head of CS Security</p>
                                    </div>
                                    <div className="author-quote">
                                        “AptEdge provides fast accurate answers, improving resolution time up by <span>40%</span>”
                                    </div>
                                </div>
                            </div>
                            <div className="card-bottom">
                                <img src={company3} />
                                <a>
                                    Fireside Chat <RightArrow />
                                </a>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="card-box-wrapper">
                    <h3>
                        <span>100+ </span>support teams globally use AptEdge to:
                    </h3>
                    <div className="card-holder">
                        <div className="card-box">
                            <p className="card-title">Increase in</p>
                            <p className="card-desc">Agent Efficiency</p>
                            <p className="card-number">50%</p>
                            <div className="progress"></div>
                        </div>
                        <div className="card-box">
                            <p className="card-title">Improve</p>
                            <p className="card-desc">Time-to-Productivity</p>
                            <p className="card-number">67%</p>
                            <div className="progress"></div>
                        </div>
                        <div className="card-box">
                            <p className="card-title">Reduce</p>
                            <p className="card-desc">Resolution Time</p>
                            <p className="card-number">40%</p>
                            <div className="progress"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageHero;
