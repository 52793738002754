import React from 'react';
import { graphql } from 'gatsby';
import HtmlHead from '../components/html-head';
import { PageData } from '../types';
import videoThumbnail from '../assets/video-thumbnail/about-aptedge-video-thumbnail.png';
import '../styles/index.css';
import MainHome from '../components/home-main';


const Homerevamp: React.FC<PageData> = resp => {
    const seoData = resp.data?.allContentfulPageSeoData?.edges[0]?.node;

    const structuredData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'VideoObject',
                'name': 'About-us-Aptedge',
                'description':
                    "We're on a mission to democratize knowledge for customer care by making knowledge actionable powering some of the biggest companies on the planet. In today's world, knowledge is not accessible relevant or properly managed.",
                'thumbnailUrl': `https://www.aptedge.io${videoThumbnail}`,
                'uploadDate': '2022-12-12',
                'duration': 'PT2M4S',
                'contentUrl': 'https://player.vimeo.com/video/779401775?h=7baac64c73',
                'embedUrl': 'https://player.vimeo.com/video/779401775?h=7baac64c73'
            },
            {
                '@type': 'WebSite',
                'name': 'AptEdge',
                'url': 'https://www.aptedge.io/',
                'potentialAction': {
                    '@type': 'SearchAction',
                    'target': 'https://www.aptedge.io/technology/{search_term_string}https://www.aptedge.io/gpt-powered/',
                    'query-input': 'required name=search_term_string'
                }
            }
        ]
    };

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage.file.url}
                structuredData={structuredData}
            />
            <MainHome/>
        </>
    );
};

export default Homerevamp;

export const query = graphql`
    query HomeQueryNew {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;
