import React from 'react';
import './home-v3-features.css';
import featureImg1 from '../../assets/home-v3/Charels Coaxum.png';
import featureImg2 from '../../assets/home-v3/Review_-_Edit.png';
import featureImg3 from '../../assets/home-v3/Meet Your Team Where They Work.webp';
import AppButton from '../CustomComponents/app-button';

function HomePageFeatures() {
    return (
        <div className="home-page-features">
            <div className="feature">
                <div className="left">
                    <div className="image">
                        <div className="feature-img">
                            <img src={featureImg1} />
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="text-container">
                        <div className="dummy-box1"></div>
                        <div className="right-content">
                            <div className="feature-content">
                                <div className="txt-box">
                                    <h1>Solve Customer Support Cases Faster</h1>
                                    <p>
                                        Centralize and streamline all knowledge access, enabling agents to immediately identify the right
                                        answers without the hassle of searching through vast amounts of data. Amplify your agents to resolve
                                        every customer case in half the time.
                                    </p>
                                    <div className="button-box">
                                        <AppButton
                                            text="Learn More"
                                            variant="variant-teal learn-more animate"
                                            linkTo="/videos/empower-agents-ai-boosting-productivity/"
                                        />
                                        <AppButton text="Demo" variant="variant-white demo animate" linkTo="/get-started" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dummy-box"></div>
                    </div>
                </div>
            </div>
            <div className="feature">
                <div className="left">
                    <div className="image">
                        <div className="feature-img">
                            <img src={featureImg2} loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="text-container">
                        <div className="dummy-box"></div>
                        <div className="right-content">
                            <div className="feature-content">
                                <div className="txt-box">
                                    {/* <h1>Close the Loop on Knowledge Gaps with Knowledge Creation</h1> */}
                                    <h1>Close Knowledge Gaps. Automatically Generate New Articles</h1>
                                    <p>
                                        AI-enabled to automatically create new knowledge articles. Answer the questions, close the case,
                                        and document new knowledge knowledge to build trust, ensure accuracy, and improve answer reliability.
                                    </p>
                                    <div className="button-box">
                                        <AppButton
                                            text="Learn More"
                                            variant="variant-teal learn-more animate"
                                            linkTo="/technology/gpt-answer-engine/"
                                        />
                                        <AppButton text="Demo" variant="variant-white demo animate" linkTo="/get-started" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dummy-box"></div>
                    </div>
                </div>
            </div>
            <div className="feature">
                <div className="left">
                    <div className="image">
                        <div className="feature-img">
                            <img src={featureImg3} loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="text-container">
                        <div className="dummy-box"></div>
                        <div className="right-content">
                            <div className="feature-content">
                                <div className="txt-box">
                                    <h1>Meet Your Team Where They Work</h1>
                                    <p>
                                        Seamlessly embed AptEdge within your native knowledge tools like Salesforce, Zendesk and Servicenow.
                                        One workspace, all the knowledge sources!
                                    </p>
                                    <div className="button-box">
                                        <AppButton
                                            text="Learn More"
                                            variant="variant-teal learn-more animate"
                                            linkTo="/blog/how-to-maximize-zendesk-customer-support-efficiency-with-answergpt"
                                        />
                                        <AppButton text="Demo" variant="variant-white demo animate" linkTo="/get-started" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dummy-box"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageFeatures;
