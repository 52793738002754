import React from 'react';
import './home-main.css';
import Navbar from '../navbar';
import HomePageHero from '../home-v3-hero';
import HomePageFeatures from '../home-v3-features';
import HomePageFAQ from '../home-page-faq';
import CtaBlock from '../components-group-one/CtaBlock';
import FeaturedContent from '../featuredContent';
import Footer from '../footer';

function MainHome() {
    return (
        <div className="main-home-page">
            <Navbar />
            <HomePageHero />
            <HomePageFeatures />
            <FeaturedContent />
            {/* <HomePageFAQ/> */}
            <CtaBlock ctaTitle="Start Today" />
            <Footer />
        </div>
    );
}

export default MainHome;
