import React, { useState, useRef } from 'react';
import { validateEmail, sendEventToGa } from '../../utils';
import validateBusinessEmail from '../../utils/validate-business-email';
import AppButton from '../CustomComponents/app-button';
import './eBook-download.css';

interface EbookDownloadProps {
    onSubmit: (downloadUrl: string) => void;
    flexCol?: boolean;
    buttonVariant?: string;
    buttonText?: string;
    downloadUrl: string;
}

const EbookDownload: React.FC<EbookDownloadProps> = ({ onSubmit, flexCol, buttonVariant, buttonText, downloadUrl }) => {
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = () => {
        setEmailError(false);
        if (!validateBusinessEmail(email)) {
            return setEmailError(true);
        }

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                'form-name': 'ebook-guide-form',
                'email': email
            }).toString()
        })
            .then(() => {
                setEmailSuccess(true);
                sendEventToGa('form_submit', { form: 'ebook-guide-form' });
                if (onSubmit) onSubmit(downloadUrl); 
            })
            .catch(error => {
                console.error(error);
                setEmailError(true);
            });
        return true;
    };

    return (
        <div className="ebook-form-wrapper" style={{ width: flexCol ? "100%" : "586px" }}>
            {emailSuccess ? (
                <div className="ebook-form-success">
                    <p className="text-md">Thanks! Your email has been recorded.</p>
                </div>
            ) : (
                <>
                    <form
                        name="ebook-guide-form"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        noValidate
                        ref={formRef}
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        style={{ flexDirection: flexCol ? "column" : "row" }}
                    >
                        <div className="input-wrapper">
                            <label id="ebook-download-email">Email</label>
                            <input
                                type="email"
                                name="email"
                                aria-labelledby="ebook-download-email"
                                placeholder="Your email address"
                                className={emailError ? 'error' : ''}
                                style={{ height: '48px' }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.currentTarget.value);
                                    setEmailError(false);
                                }}
                            />
                            <p className={emailError ? 'input-error error' : 'input-error'}>Please enter a valid email address</p>
                        </div>
                        <input type="hidden" name="form-name" value="ebook-guide-form" />
                        <AppButton
                            variant={buttonVariant ?? 'variant-teal'}
                            text={buttonText || 'Subscribe'}
                            hasArrow
                            style={{ fontWeight: "400", lineHeight: "0", marginLeft: flexCol ? "0em" : "1em", maxWidth: "165px" }}
                        />
                    </form>
                </>
            )}
        </div>
    );
};

export default EbookDownload;
