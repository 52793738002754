import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import './featuredcontent.css';
import GuideBook from '../../assets/home-v3/AptEdge Whitepaper.pdf';
import TrustedClientLogos from '../home-logos';
import EbookDownload from '../eBook-download';

function FeaturedContent() {
    const ref = useRef<HTMLDivElement | null>(null);
    const [show, setShow] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    // GraphQL query to fetch the latest blog post with body
    const data = useStaticQuery(graphql`
        query LatestBlogPost {
            allContentfulBlogPosts(sort: { fields: createdAt, order: DESC }, limit: 1) {
                edges {
                    node {
                        slug
                        title
                        body {
                            raw
                        }
                        image {
                            fluid(maxHeight: 540, quality: 100) {
                                srcWebp
                            }
                        }
                    }
                }
            }
        }
    `);

    const latestPost = data.allContentfulBlogPosts.edges[0]?.node;

    function handleShowEmail() {
        setShowEmail(!showEmail);
    }

    function handleEmailSubmit(downloadUrl: string) {
        setEmailSubmitted(true);
        setShowEmail(false);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = downloadUrl.split('/').pop() || 'ebook.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setShow(true);
                    }
                });
            },
            {
                threshold: 0,
                rootMargin: '100px'
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
    }, []);

    return (
        <div className="featured" ref={ref}>
            <TrustedClientLogos />
            <p className="featured-title teal">Featured Content</p>
            <div className="featured-box">
                <div className="featured-container">
                    <div className="featured-container-img-container">
                        <StaticImage
                            src="../../assets/home-v3/Version 3.png"
                            alt="genrative-book"
                            placeholder="none"
                            loading="eager"
                            className="featured-image"
                        />
                    </div>
                    <div className="text-content">
                        <h4 className="text-h4">Build or Buy?</h4>
                        <p className="text-desc">
                            Download the playbook to building your AI Native Case Resolution platform.
                        </p>
                        {/* <span className="text-span">Acceleration Economy!</span> */}
                        <div className="featured-btn-box">
                            {!emailSubmitted ? (
                                <button className="featured-btn" onClick={handleShowEmail}>
                                    Download Whitepaper
                                </button>
                            ) : (
                                <a href={GuideBook} download>
                                    <button className="featured-btn">Download Whitepaper</button>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                {latestPost && (
                    <div className="featured-container">
                        <div className="featured-container-img-container">
                            <img
                                src={latestPost.image.fluid.srcWebp}
                                alt={latestPost.title}
                                className="featured-image"
                            />
                        </div>
                        <div className="text-content">
                            <h4 className="text-h4">Latest Blog</h4>
                            <p className="text-desc">{latestPost.title}</p>
                            <div className="featured-btn-box">
                                <Link to={`/blog/${latestPost.slug}`}>
                                    <button className="featured-btn">Take a Look</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="featured-container">
                    <div className="featured-container-img-container">
                        <StaticImage
                            src="../../assets/resources/Charles Video Page.jpg"
                            alt="Other Featured"
                            placeholder="none"
                            loading="eager"
                            className="featured-image"
                        />
                    </div>
                    <div className="text-content">
                        <h4 className="text-h4">Latest Video</h4>
                        <p className="text-desc">Fireside Chat; Future of Support</p>
                        <div className="featured-btn-box">
                            <Link to={'/videos/fireside-chat-future-customer-support/'}>
                                <button className="featured-btn">Take a Look</button>
                            </Link>
                        </div>
                    </div>
                </div>
                {showEmail && (
                    <div className="overlay" onClick={handleShowEmail}>
                        <div className="subscribe-container" onClick={(e) => e.stopPropagation()}>
                            <EbookDownload onSubmit={handleEmailSubmit} buttonText="Download Whitepaper" downloadUrl={GuideBook} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FeaturedContent;
